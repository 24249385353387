const config = {
  apiGateway: {
    REGION: 'us-east-1',
    INTERNAL_API_URL: 'https://70e6h9pb24.execute-api.us-east-1.amazonaws.com/prod',
    TARGET_API_URL: 'https://js5nl0ppz4.execute-api.us-east-1.amazonaws.com/api',
    TEAMS_APP_URL: 'https://teams.testing.riotinto.forwoodsafety.com/teams',
    QS_DASHBOARD_URL: '',
    CLIENT_TIMEZONE: 'America/Sao_Paulo'
  },
  reactApp: {
    VERSION: 'DO-2460-combined-aurora-db-solution',
    HOSTNAME: 'https://targets.testing.riotinto.forwoodsafety.com'
  }
};

export default config;